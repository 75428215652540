<template>
  <div class="row brands">
    <b-col lg="12">
    <div class="col-md-12 mt-2">
      <b-card>
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="form-group row">
          
            <label for="" class="col-sm-2"> Search Brand <br /> </label>
            <b-col md="6">
              <b-form-input placeholder="Type Here to Search" type="search" v-model="query_search" @input="onSearch"
                v-on:keyup.enter="onSearch(query_search)"></b-form-input>
              <small v-if="typing"> <em> typing ... </em> </small>
            </b-col>
          
        </div>
        <b-row class="justify-content-end pb-1">
          <span class="pr-2">
            <router-link class="btn btn-sm btn-dark col-sm-auto" :to="CreateBrandPath">
              <em class="fa fa-plus"></em>
            </router-link>
          </span>
          <button class="btn btn-sm btn-dark col-sm-auto" @click="refreshPage">
            <em class="fa fa-refresh"></em>
          </button>
          <label class="col-sm-auto">Count </label>
          <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
        </b-row>
        <div class="table-responsesive">
        <b-table 
            sticky-header="600px"
          :items="brands"
          :fields="fields"
          :busy="isLoading"
          :isLoading="isLoading"
          show-empty
          responsive="xl"
          hover
          sort-icon-left
        >
          <template #cell(No)="row">
            {{ (currentPage - 1) * perPage + (row.index + 1)}}
          </template>
          <template #cell(name)="row">
            <router-link :to="{path :'/brands/editBrands/' + row.item.id}" class="link"> 
              {{row.item.name }}
            </router-link>
          </template>

          <template v-slot:cell(is_active)="row">
            <div>
              <span v-if="row.item.is_active" class="badge badge-success">Active</span>
              <span v-else class="badge badge-danger"> InActive </span>
            </div>
          </template>

          <template #cell(action_edit_brands)="row">
            <router-link class="btn btn-default"
              :to="{ path: '/brands/editBrands/'+ row.item.id }"
            >
              <i class="fa fa-edit">
              </i>
            </router-link>
          </template>

          <template #cell(action_edit_category)="row">
            <router-link class="btn btn-default"
              :to="{ path: '/brands/displayCategory/'+ row.item.id }"
            >
              <i class="fa fa-edit">
              </i>
            </router-link>
          </template>

          <template #cell(action_edit_premiums)="row">
            <router-link class="btn btn-default"
            :to="{ path: '/brands/displayPremiums/'+ row.item.id }"
            >
              <i class="fa fa-edit">
              </i>
            </router-link>
          </template>
        </b-table>
        </div>
        <b-row class="justify-content-start">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              @change="onClickPage"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
    </b-col>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { CreateBrandPath } from '../../router/clients';

export default {
  name: "Brand",
  data() {
    return {
      CreateBrandPath,
      currentPage: 1,
      perPage: 20,
      typing: false,
      query_search: null,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      debounce: null,
      fields: [
        { key:'No', label: 'No'},
        { key:'name', label: 'Name'},
        { key:'brand_code', label: 'Code'},
        { key:'vendor_name', label: 'Vendor'},
        { key:'is_active', label: 'Status'},
        { key :'action_edit_brands', label:'Edit Brand'},
        { key :'action_edit_category', label:'Edit Category'},
        { key :'action_edit_premiums', label:'Edit Premium Offers'},
      ]
    };
  },
  watch: {
    currentPage: function() {
      this.actionGetBrand();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Brands | Content Management System Ebooks Gramedia.com';
      }
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.brands.isLoading,
      isError: (state) => state.brands.isError,
      totalRows: (state) => state.brands.totalRows,
      brands: (state) => state.brands.items,
    }),
  },
  mounted() {
    this.actionGetBrand();
  },
  methods: {
    ...mapActions("brands", ["fetchBrands", "searchBrands"]),
    actionGetBrand() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if(this.query_search) {
        this.typing = true;
        payload.name = this.query_search;
        this.isLoadingSearch = true;
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.searchBrands(payload).then((response) => {
          this.items_search = response.data.data.rows;
          this.isLoadingSearch = false;
          this.typing = false;
        }).catch(() => {
          this.isLoadingSearch = false;
        });
      }, 600);

      } else {
        this.fetchBrands(payload);
      }
    },
    onClickPage(page) {
      this.currentPage = page;
      this.actionGetBrand();
    },
    onSearch(q) {
      this.query_search = q;
      this.actionGetBrand();
    },
    refreshPage() {
      this.query_search = null;
      this.currentPage = 1;
      this.actionGetBrand();
    },
    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      return '0';
    },
  },
};
</script>
<style scoped>

</style>
