var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row brands"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "col-md-12 mt-2"
  }, [_c('b-card', [_vm.isError ? _c('b-row', {
    staticClass: "justify-content-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" Opps .. Something is wrong ")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Search Brand "), _c('br')]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Here to Search",
      "type": "search"
    },
    on: {
      "input": _vm.onSearch,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onSearch(_vm.query_search);
      }
    },
    model: {
      value: _vm.query_search,
      callback: function ($$v) {
        _vm.query_search = $$v;
      },
      expression: "query_search"
    }
  }), _vm.typing ? _c('small', [_c('em', [_vm._v(" typing ... ")])]) : _vm._e()], 1)], 1), _c('b-row', {
    staticClass: "justify-content-end pb-1"
  }, [_c('span', {
    staticClass: "pr-2"
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-dark col-sm-auto",
    attrs: {
      "to": _vm.CreateBrandPath
    }
  }, [_c('em', {
    staticClass: "fa fa-plus"
  })])], 1), _c('button', {
    staticClass: "btn btn-sm btn-dark col-sm-auto",
    on: {
      "click": _vm.refreshPage
    }
  }, [_c('em', {
    staticClass: "fa fa-refresh"
  })]), _c('label', {
    staticClass: "col-sm-auto"
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.formatNumber(_vm.totalRows)))])]), _c('div', {
    staticClass: "table-responsesive"
  }, [_c('b-table', {
    attrs: {
      "sticky-header": "600px",
      "items": _vm.brands,
      "fields": _vm.fields,
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "show-empty": "",
      "responsive": "xl",
      "hover": "",
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              path: '/brands/editBrands/' + row.item.id
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")])];
      }
    }, {
      key: "cell(is_active)",
      fn: function (row) {
        return [_c('div', [row.item.is_active ? _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v("Active")]) : _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v(" InActive ")])])];
      }
    }, {
      key: "cell(action_edit_brands)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "btn btn-default",
          attrs: {
            "to": {
              path: '/brands/editBrands/' + row.item.id
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        })])];
      }
    }, {
      key: "cell(action_edit_category)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "btn btn-default",
          attrs: {
            "to": {
              path: '/brands/displayCategory/' + row.item.id
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        })])];
      }
    }, {
      key: "cell(action_edit_premiums)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "btn btn-default",
          attrs: {
            "to": {
              path: '/brands/displayPremiums/' + row.item.id
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        })])];
      }
    }])
  })], 1), _c('b-row', {
    staticClass: "justify-content-start"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.onClickPage
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }